import 'core-js';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { createBrowserHistory } from 'history';

import { SnackbarProvider } from 'notistack';
import Toast from './components/utils/toast/toast';

import Header from './components/navigation/header/header';
import Footer from './components/navigation/footer/footer';
import useWindowResize from './components/utils/useWindowResize';

import Home from './pages/home/home';

import analytics from './values/analytics';

import './main.scss';
import scroller from './values/scroller';

const endpoints = require('./values/endpoints');

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Raleway", sans-serif',
  },
  primary: {
    light: '#334861',
    main: '#192E47',
    dark: '#0C213A',
  },
  secondary: {
    light: '#F3F3F3',
    main: '#F3F3F3',
    dark: '#F3F3F3',
  },
});

function App() {
  const [denseNotifications, setDenseNotifications] = useState(window.innerWidth <= 600);
  useEffect(() => {
    analytics.init();
    scroller();
  }, []);
  useWindowResize(() => {
    if ((window.innerWidth <= 600 && !denseNotifications) || (window.innerWidth > 600 && denseNotifications)) {
      setDenseNotifications(window.innerWidth <= 600);
    }
  });
  return (
    <StylesProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} dense={denseNotifications}>
          <Header />
          <main>
            <Router history={createBrowserHistory()}>
              <Switch>
                <Route exact path={endpoints.client.home} component={Home} />
              </Switch>
            </Router>
          </main>
          <Footer />
          <Toast />
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, document.getElementById('root'));
});
