import React, { useEffect } from 'react';

import Hero from '../../components/home/hero';
import About from '../../components/home/about';
import Divider from '../../components/home/divider';
import Portfolio from '../../components/home/portfolio/portfolio';
import Team from '../../components/home/team/team';
import Contact from '../../components/home/contact/contact';
import Address from '../../components/home/address';

import page from '../../values/page';
import captcha from '../../values/captcha';

const strings = require('../../values/strings');
const endpoints = require('../../values/endpoints');

export default function Home() {
  useEffect(() => {
    page.setTitle(strings.navigation.home.title);
    page.setDescription(strings.navigation.home.description);
    page.setCanonical(endpoints.client.home);
    page.organisation();
    page.setOpenGraph({
      title: strings.navigation.home.title,
      description: strings.navigation.home.description,
    });
    page.setLoaded();
    captcha.action('home');
  }, []);
  return (
    <>
      <Hero />
      <About />
      <Divider />
      <Portfolio />
      <Team />
      <Contact />
      <Divider />
      <Address />
    </>
  );
}
