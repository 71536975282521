import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';

const endpoints = require('../../../values/endpoints');
const strings = require('../../../values/strings');

function HeaderNavigationMobileElement(props) {
  let selected = props.href === window.location.pathname;
  return (
    <a
      data-analytics={props['data-analytics']}
      rel='noopener noreferrer'
      href={props.href}
      onClick={props.onClick}
      className={'header-navigation-mobile-link' + (selected ? ' header-navigation-mobile-link-selected' : '')}>
      <div className='header-navigation-mobile-link-icon'>{props.icon}</div>
      {props.children}
    </a>
  );
}

HeaderNavigationMobileElement.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  'data-analytics': PropTypes.string.isRequired,
};

export default function HeaderNavigationMobile() {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <IconButton onClick={() => setOpen(true)} data-analytics='header-navigation-mobile-open' >
          <MenuIcon className='header-navigation-mobile-navigation' />
        </IconButton>
        <Grow in={open}>
          <Card className='header-navigation-mobile-card'>
            <div className='header-navigation-mobile-container'>
              <div className='header-navigation-mobile-logo-container'>
                <a data-analytics='header-logo' href={endpoints.client.home} className='header-navigation-mobile-logo'>
                  <img src='/assets/img/logo-header-dark.png' alt={strings.company.name} className='header-navigation-mobile-logo-image' />
                </a>
                <div className='header-navigation-mobile-logo-spacer' />
                <IconButton onClick={() => setOpen(false)} data-analytics='header-navigation-mobile-close' >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className='header-navigation-mobile-contents'>
              <HeaderNavigationMobileElement
                icon={<img alt={strings.company.name} src='/assets/img/logo-square-dark.png' className='header-navigation-mobile-link-icon-img' />}
                href={endpoints.client.about}
                onClick={() => setOpen(false)}
                data-analytics='header-navigation-mobile-about'>
                {strings.navigation.about.title}
              </HeaderNavigationMobileElement>
              <HeaderNavigationMobileElement
                icon={<BusinessIcon />}
                href={endpoints.client.portfolio}
                onClick={() => setOpen(false)}
                data-analytics='header-navigation-mobile-portfolio'>
                {strings.navigation.portfolio.title}
              </HeaderNavigationMobileElement>
              <HeaderNavigationMobileElement
                icon={<GroupIcon />}
                href={endpoints.client.team}
                onClick={() => setOpen(false)}
                data-analytics='header-navigation-mobile-team'>
                {strings.navigation.team.title}
              </HeaderNavigationMobileElement>
              <HeaderNavigationMobileElement
                icon={<HeadsetMicIcon />}
                href={endpoints.client.contact}
                onClick={() => setOpen(false)}
                data-analytics='header-navigation-mobile-contact'>
                {strings.navigation.contact.title}
              </HeaderNavigationMobileElement>
            </div>
          </Card>
        </Grow>
      </div>
    </ClickAwayListener>
  );
}