import { useEffect, useRef } from 'react';

export default function useWindowResize(func) {
  const currentFunction = useRef(func);
  useEffect(() => {
    if (typeof currentFunction.current === 'function') {
      let onResize = () => currentFunction.current();
      window.addEventListener('resize', onResize, { passive: true });
      return () => {
        currentFunction.current = null;
        window.removeEventListener('resize', onResize);
      }
    }
  }, []);
}