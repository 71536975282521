import React from 'react';
import PropTypes from 'prop-types';

const endpoints = require('../../../values/endpoints');
const strings = require('../../../values/strings');

function HeaderNavigationElement(props) {
  let selected = props.href === window.location.pathname;
  let className = 'header-navigation-link';
  if (selected) {
    className += ' header-navigation-link-selected';
  }
  return (
    <a data-analytics={props['data-analytics']} href={props.href} className={className}>
      {props.children}
    </a>
  );
}

HeaderNavigationElement.propTypes = {
  href: PropTypes.string.isRequired,
  'data-analytics': PropTypes.string.isRequired,
};

export default function HeaderNavigation() {
  return (
    <div className='header-navigation'>
      <HeaderNavigationElement data-analytics='header-navigation-desktop-about' href={endpoints.client.about}>
        {strings.navigation.about.title}
      </HeaderNavigationElement>
      <HeaderNavigationElement data-analytics='header-navigation-desktop-portfolio' href={endpoints.client.portfolio}>
        {strings.navigation.portfolio.title}
      </HeaderNavigationElement>
      <HeaderNavigationElement data-analytics='header-navigation-desktop-team' href={endpoints.client.team}>
        {strings.navigation.team.title}
      </HeaderNavigationElement>
      <HeaderNavigationElement data-analytics='header-navigation-desktop-contact' href={endpoints.client.contact}>
        {strings.navigation.contact.title}
      </HeaderNavigationElement>
    </div>
  );
}