const moment = require('moment');

module.exports = {

  company: {
    name: 'Key Capital',
    legal: 'Key Capital Ltd',
    address: '21 Great South Rd, Newmarket, Auckland 1051, New Zealand',
    url: window.location.origin,
    email: {
      display: 'contact@keycapital.co.nz',
      actual: 'contact@keycapital.co.nz',
    },
  },
  tagline: 'Key Capital is an active add value commercial property investment company specialising in the Auckland region',
  description: 'Key Capital is an active add value commercial property investment company specialising in the Auckland region',
  copyright: 'Copyright © ' + moment().format('YYYY') + ' Key Capital Ltd. All rights reserved.\nAll product names, logos, and brands are property of their respective owners.',
  credits: {
    title: 'Credits',
    body: 'Extra assets made available by:',
    close: 'Close',
  },

  failure: 'Oops! Something went wrong, please reload and try again, or contact us via email for help.',

  navigation: {
    home: {
      title: 'Home',
      description: 'Key Capital is an active add value commercial property investment company specialising in the Auckland region',
    },
    about: {
      title: 'About',
      description: 'Learn more about what we do and how we may help you.',
    },
    portfolio: {
      title: 'Portfolio',
      description: 'See a list of the clients we\'ve worked with and the solutions we\'ve provided.',
    },
    team: {
      title: 'Team',
      description: 'Meet our expert team and learn how their experience may be able to help you.',
    },
    contact: {
      title: 'Contact',
      description: 'Have any questions or want to speak to someone? Get in contact today and let us help with your real estate needs.',
    },
  },

  about: {
    title: 'Key Capital is an active add value commercial property investment company specialising in the Auckland region',
    button: 'Get in touch',
  },

  portfolio: {
    title: 'Portfolio',
    sold: 'Sold',
    button: {
      more: 'See more',
      less: 'See less',
      close: 'Close',
    },
  },

  team: {
    title: 'Our Team',
    button: 'Close',
  },

  contact: {
    title: 'Get in Touch',
    body: 'If you\'d like to learn how we may be able to help you,\nplease get in contact and we\'ll get back to you as soon as we can.',
    inputs: {
      name: 'First Name',
      company: 'Company',
      email: 'Email',
      phone: 'Phone Number',
      message: {
        title: 'Message',
        placeholder: 'Tell us a little more about your needs, e.g. are you looking at buying or selling?',
      },
    },
    button: 'Submit',
    captcha: 'Sorry, our spam filter has caught you out. Apologies for this, please send us an email instead at\n<a href="mailto:contact@keycapital.co.nz?Subject=Contact%20Us" data-analytics="contact-captcha" target="_blank" rel="noopener noreferrer">contact@keycapital.co.nz</a>.',
    success: 'Thanks, we\'ll get back to you as soon as we can.',
    error: 'Something went wrong, please check your details and try again.',
  },

  address: {
    address: '21 Great South Rd\nNewmarket\nAuckland 1051\nNew Zealand',
    maps: 'Google Maps Location',
  }

}
