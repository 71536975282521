import React from 'react';
import PropTypes from 'prop-types';

import PhoneIcon from '@material-ui/icons/Phone';

function TeamElement(props) {
  return (
    <div className='team-element-root'>
      <span className='team-element-name'>{props.member.name}</span>
      <span className='team-element-role'>{props.member.role}</span>
      <span className='team-element-description'>{props.member.description}</span>
      <a
        href={'tel:' + props.member.phone.actual}
        target='_blank'
        rel='noopener noreferrer'
        className='team-element-phone'>
        <PhoneIcon className='team-element-phone-icon' />
        {props.member.phone.formatted}
      </a>
    </div>
  );
}

TeamElement.propTypes = {
  member: PropTypes.object.isRequired,
};

export default TeamElement;