import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import PortfolioElementCover from './portfolio-element-cover';
import useWindowResize from '../../utils/useWindowResize';

export function PortfolioEmptyElement() {
  return (
    <div className='portfolio-element-root portfolio-element-empty' />
  );
}

function PortfolioElement(props) {
  const [height, setHeight] = useState();
  const containerRef = useRef();
  const resizeSensor = useRef();
  const onResize = () => {
    if (containerRef.current) {
      setHeight(containerRef.current.offsetWidth);
    }
  };
  useEffect(() => {
    onResize();
    return () => {
      try { resizeSensor.current.detach(); } catch (err) { }
      resizeSensor.current = null;
      containerRef.current = null;
    };
  }, []);
  let onContainerRef = (container) => {
    containerRef.current = container;
    onResize();
  };
  useWindowResize(onResize);
  return (
    <div
      ref={onContainerRef}
      className='portfolio-element-root'
      style={{ height: height && !isNaN(height) ? height + 'px' : undefined }}>
      <img
        alt={props.item.title}
        loading='lazy'
        src={props.item.image}
        className='portfolio-element-image' />
      <PortfolioElementCover item={props.item} />
    </div>
  );
}

PortfolioElement.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PortfolioElement;