module.exports = [

  {
    title: '21 Great South Road, Newmarket',
    image: '/assets/img/maserati.jpg',
  },

  {
    title: '265 Great South Road, Greenlane',
    image: '/assets/img/265-great-south-road.jpg',
  },

  {
    title: '2 Pacific Rise, Penrose',
    image: '/assets/img/2-pacific-rise.jpg',
  },

  {
    title: '135G Pilkington Road, Panmure',
    image: '/assets/img/135g-pilkington-road.jpg',
  },

  {
    title: '154 Harris Road, East Tamaki',
    image: '/assets/img/154-harris-road.jpg',
  },

  {
    title: '19/21 Greenpark Road, Penrose',
    image: '/assets/img/19-21-greenpark-road.png',
  },

  {
    title: '24 Manukau Road, Epsom',
    image: '/assets/img/24-manukau-road.jpg',
  },

  {
    title: '5 Hillside Road, Wairau Valley',
    image: '/assets/img/skoda.jpg',
  },

  {
    title: '1 Hillside Road, Wairau Valley',
    image: '/assets/img/volkswagen.jpg',
  },

  // {
  //   title: '57 Market Road, Epsom',
  //   sold: true,
  //   image: '/assets/img/whangamata.jpg',
  // },

  // {
  //   title: '6 Arawa Street, Grafton',
  //   image: '/assets/img/6-arawa.jpg',
  // },

  // {
  //   title: 'Whangamata Motor Camp',
  //   image: '/assets/img/whangamata.jpg',
  // },

  {
    title: 'Marine Reserve Apartments',
    image: '/assets/img/marine-reserve.jpg',
  },

  {
    title: '13 Margot Street, Epsom',
    image: '/assets/img/margot-street.jpg',
  },

  // {
  //   title: '151 Pilkington Road, Panmure',
  //   image: '/assets/img/pilkington-road.jpg',
  // },

  {
    title: '279 Great South Road, Greenlane',
    image: '/assets/img/279-great-south.jpg',
  },

  {
    title: '22b Greenpark Road, Penrose',
    image: '/assets/img/greenpark-road.jpg',
  },

  {
    title: '8 Margot Street, Epsom',
    image: '/assets/img/8-margot-street.jpg',
  },

  // contact@keycapital.co.nz
  // Lam18713

];