import React, { Component } from 'react';

import { withSnackbar } from 'notistack';

let instance = null;

class Toast extends Component {
  static info(message, values) {
    Toast.show(message, Object.assign({}, values ? values : {}, { variant: 'info' }));
  }
  static warning(message, values) {
    Toast.show(message, Object.assign({}, values ? values : {}, { variant: 'warning' }));
  }
  static success(message, values) {
    Toast.show(message, Object.assign({}, values ? values : {}, { variant: 'success' }));
  }
  static error(message, values) {
    Toast.show(message, Object.assign({}, values ? values : {}, { variant: 'error' }));
  }
  static show(message, values) {
    Toast.enqueueSnackbar(message, values);
  }
  static enqueueSnackbar(message, values) {
    if (process.browser && instance && instance.props && instance.props.enqueueSnackbar && message && message.length > 0) {
      if (typeof values !== 'object') {
        values = {};
      }
      if (!values.variant || values.variant.length === 0) {
        values.variant = 'default';
      }
      if (!('persist' in values) || typeof values.persist !== 'boolean') {
        values.persist = false;
      }
      instance.props.enqueueSnackbar(message, values);
    }
  }
  componentDidMount() {
    instance = this;
  }
  render() {
    return <></>;
  }
}

export default withSnackbar(Toast);