import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const strings = require('../../../values/strings');

function PortfolioSeeMore(props) {
  return (
    <div className='portfolio-see-more-root'>
      <Button
        data-analytics='portfolio-see-more'
        component='button'
        endIcon={props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={props.onClick}
        className='portfolio-see-more-button'>
        {props.open ? strings.portfolio.button.less : strings.portfolio.button.more}
      </Button>
    </div>
  );
}

PortfolioSeeMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PortfolioSeeMore;