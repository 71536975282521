import React from 'react';

import TeamElement from './team-element';

const team = require('../../../values/team');

export default function TeamMobile() {
  return (
    <div className='team-mobile-root'>
      <div className='team-mobile-row'>
        <TeamElement member={team.andy} />
      </div>
      <div className='team-mobile-row'>
        <TeamElement member={team.wayne} />
      </div>
      <div className='team-mobile-row'>
        <TeamElement member={team.will} />
      </div>
    </div>
  );
}