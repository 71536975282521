import React from 'react';

import TeamElement from './team-element';

const team = require('../../../values/team');

export default function TeamDesktop() {
  return (
    <div className='team-desktop-root'>
      <div className='team-desktop-row'>
        <TeamElement member={team.wayne} />
        <TeamElement member={team.andy} />
      </div>
      <div className='team-desktop-row'>
        <TeamElement member={team.will} />
      </div>
    </div>
  );
}