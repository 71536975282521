import React, { useState, useEffect } from 'react';

import ContactApi from '../../../api/contact';

import CircularProgress from '@material-ui/core/CircularProgress';

import ContactForm from './contact-form';

import CaptchaController from '../../../values/captcha';

import Toast from '../../utils/toast/toast';

const strings = require('../../../values/strings');
const endpoints = require('../../../values/endpoints');

export default function Contact() {
  const [captcha, setCaptcha] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState(false);
  useEffect(() => setErrors(false), [name, company, email, phone, message]);
  useEffect(() => {
    let listener = (verified) => setCaptcha(verified);
    CaptchaController.addListener(listener);
    return () => {
      CaptchaController.removeListener(listener);
    };
  }, []);
  const onSubmit = async () => {
    setLoading(true);
    try {
      await new ContactApi().submit({
        name: name,
        company: company,
        email: email,
        phone: phone,
        message: message,
      });
      setName('');
      setCompany('');
      setEmail('');
      setPhone('');
      setMessage('');
      Toast.success(strings.contact.success);
    } catch (err) {
      Toast.error(strings.contact.error);
      setErrors(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section id={endpoints.client.contact.replace('#', '')} className='contact-root'>
      <div className='contact-container'>
        <h2 className='contact-heading'>
          {strings.contact.title}
        </h2>
        <span className='contact-description'>
          {strings.contact.body}
        </span>
        {
          loading &&
          <div className='contact-spinner'>
            <CircularProgress />
          </div>
        }
        <div className='contact-wrapper' style={{ display: loading ? 'none' : undefined }}>
          <ContactForm
            captcha={captcha}
            name={name}
            onNameChange={(value) => setName(value)}
            company={company}
            onCompanyChange={(value) => setCompany(value)}
            email={email}
            onEmailChange={(value) => setEmail(value)}
            phone={phone}
            onPhoneChange={(value) => setPhone(value)}
            message={message}
            onMessageChange={(value) => setMessage(value)}
            errors={errors}
            onSubmit={onSubmit} />
          {
            !captcha &&
            <span
              dangerouslySetInnerHTML={{ __html: strings.contact.captcha }}
              className='contact-captcha' />
          }
        </div>
      </div>
    </section>
  );
}