import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useWindowResize from '../../utils/useWindowResize';
import PortfolioDialog from './portfolio-dialog';

const constants = require('../../../values/constants');

function PortfolioElementCover(props) {
  const [padding, setPadding] = useState();
  const [focused, setFocused] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const containerRef = useRef();
  const titleRef = useRef();
  let onFocus = () => setFocused(false);
  let onUnfocus = () => setFocused(false);
  let onResize = () => {
    if (containerRef.current && titleRef.current) {
      let containerParent = constants.getParent(containerRef.current);
      if (containerParent) {
        setPadding(containerParent.offsetHeight - titleRef.current.offsetHeight);
      }
    }
  };
  let onContainerRef = (container) => {
    containerRef.current = container;
    onResize();
  };
  let onTitleRef = (title) => {
    titleRef.current = title;
    onResize();
  };
  useEffect(() => {
    onResize();
    return () => {
      containerRef.current = null;
      titleRef.current = null;
    };
  }, []);
  useWindowResize(onResize);
  return (
    <>
      <ClickAwayListener onClickAway={onUnfocus}>
        <div
          ref={onContainerRef}
          data-analytics='portfolio-element'
          tabIndex={0}
          role='button'
          onClick={onFocus}
          onKeyUp={onFocus}
          onMouseEnter={onFocus}
          onMouseLeave={onUnfocus}
          className='portfolio-element-cover-root'
          style={{ paddingTop: padding && !isNaN(padding) && !focused ? padding + 'px' : 0 }}>
          <div ref={onTitleRef} className={'portfolio-element-cover-title' + (focused ? ' portfolio-element-cover-title-focused' : '')}>
            {props.item.title}
          </div>
          <div
            onClick={() => setDialogOpen(true)}
            onKeyUp={onFocus}
            role='button'
            tabIndex={-1}
            className='portfolio-element-cover-body-container'>
            <span className='portfolio-element-cover-body-copy'>
              {/* {props.item.body} */}
            </span>
            <div className='portfolio-element-cover-body-button-container'>
              <IconButton data-analytics='portfolio-element-more'>
                <ChevronRightIcon className='portfolio-element-cover-body-button-icon' />
              </IconButton>
            </div>
          </div>
        </div>
      </ClickAwayListener>
      <PortfolioDialog open={dialogOpen} item={props.item} onClose={() => setDialogOpen(false)} />
    </>
  );
}

PortfolioElementCover.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PortfolioElementCover;