import CaptchaApi from '../api/captcha';

const constants = require('./constants');

let score = constants.isProduction() ? null : 1;
let listeners = [];

export default class captcha {
  static action(action) {
    let onCaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(constants.captcha, { action: action })
            .then((token) => {
              new CaptchaApi().verify(action, token)
                .then((response) => {
                  if (response.data && response.data.success && response.data.payload && response.data.payload.success && !isNaN(response.data.payload.score)) {
                    score = constants.isProduction() ? response.data.payload.score : 1;
                  }
                }).catch((err) => {
                  score = constants.isProduction() ? 0 : 1;
                  if (!constants.isProduction()) {
                    console.log(err);
                  }
                }).finally(() => {
                  if (!score || isNaN(score)) {
                    score = constants.isProduction() ? 0 : 1;
                  }
                  for (let listener of listeners) {
                    try { listener(captcha.isVerified(), captcha.getScore()); } catch (err) { }
                  }
                });
            });
        });
      }
      else {
        setTimeout(onCaptcha, 300);
      }
    };
    onCaptcha();
  }
  static isVerified() {
    return score && !isNaN(score) ? score >= 0.5 : false;
  }
  static getScore() {
    return score;
  }
  static addListener(listener) {
    if (listener && typeof listener === 'function') {
      listeners.push(listener);
      if (score && !isNaN(score)) {
        try { listener(captcha.isVerified(), captcha.getScore()); } catch (err) { }
      }
    }
  }
  static removeListener(listener) {
    listeners = listeners.filter(l => l !== listener);
  }
}