const constants = require('./constants');

export default class analytics {
  static init() {
    window.addEventListener('error', (error) => {
      let message = 'Fatal exception occured! Error: "' + (error && error.message ? error.message : JSON.stringify(error)) + '"';
      if (constants.isProduction()) {
        window.gtag('event', 'exception', {
          'description': message,
          'fatal': true
        });
      }
    });
    window.addEventListener('click', (event) => {
      let getElementWithTagInChain = (element) => {
        if (element != null && (element instanceof Element || element instanceof HTMLDocument) && (typeof element.getAttribute === 'function')) {
          if (element.getAttribute('data-analytics') && element.getAttribute('data-analytics').length > 0) {
            let value = element.getAttribute('data-analytics-value');
            if (isNaN(value)) {
              try {
                value = parseInt(value);
              } catch (err) {
                value = null;
              }
            }
            let details = {
              'event_label': element.getAttribute('data-analytics'),
              'value': value,
            };
            if (!constants.isProduction()) {
              console.log(details);
            }
            else {
              window.gtag('event', 'click', details);
            }
          }
          else {
            getElementWithTagInChain(constants.getParent(element));
          }
        }
        else if (element) {
          getElementWithTagInChain(constants.getParent(element));
        }
        else if (!constants.isProduction()) {
          console.log('\n-------------------------------------');
          console.log('Click on element resulted in no data analytics:');
          console.log(event.target);
          console.log('-------------------------------------\n');
        }
      };
      getElementWithTagInChain(event.target);
    });
  }
  static onPageLoad() {
    if (window.gtag && constants.isProduction()) {
      window.gtag('event', 'timing_complete', {
        'name': 'load',
        'value': Math.round(performance.now()),
      });
    }
  }
  static onException(msg, err) {
    if (window.gtag && constants.isProduction()) {
      let message = 'Exception:\n' + msg;
      if (err && err.message && err.message.length > 0) {
        message += '\n\nError:\n' + err.message;
      }
      window.gtag('event', 'exception', {
        'description': message,
        'fatal': false
      });
    }
  }
}