module.exports = {

  andy: {
    name: 'Andy Doole',
    role: 'Director',
    phone: {
      actual: '+64275185522',
      formatted: '027 518 5522',
    },
    description: 'Andy has extensive experience in sourcing and investing in commercial property, redevelopment projects, subdivisions, property management and lease negotiations. In addition, he is responsible for the daily management of Key Capital and associated companies. Andy holds a BSBA (Hons) degree majoring in economics and management from Rockhurst University (USA). ',
    image: '/assets/img/andy.jpg',
  },

  wayne: {
    name: 'Wayne Leach',
    role: 'Director',
    phone: {
      actual: '+6421777778',
      formatted: '021 777 778',
    },
    description: 'A successful businessman with over 30 year\'s experience as owner of Winger Motors Ltd - one of New Zealand\'s largest car automotive dealership networks. Wayne has substantial experience in the commercial and residential property market comprising of significant residential lot subdivisions, green field commercial developments, and add value commercial investment properties.',
    image: '/assets/img/wayne.jpg',
  },

  will: {
    name: 'Will Boswell',
    role: 'Property Manager',
    phone: {
      actual: '+64212339605',
      formatted: '021 233 9605',
    },
    description: 'Will supports the team with the daily oversight of assets and assistance in projects that Key Capital and associated companies manage. Following on from the conclusion of his studies at The University of Otago (BCom & BA Minor) and prior to joining the Key Capital team he was working as a CBD/Area Sales Manager at Fujifilm. It was here that he started to develop skills in customer relationship management, lease negotiations and strategic thinking',
    image: '/assets/img/will.jpg',
  },

};