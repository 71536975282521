import React, { useState, useEffect, useRef } from 'react';

import PortfolioDesktop from './portfolio-desktop';
import PortfolioTablet from './portfolio-tablet';
import PortfolioMobile from './portfolio-mobile';

import useWindowResize from '../../utils/useWindowResize';

const strings = require('../../../values/strings');
const portfolio = require('../../../values/portfolio');
const endpoints = require('../../../values/endpoints');
const constants = require('../../../values/constants');

export default function Portfolio() {
  const [loading, setLoading] = useState(true);
  const sizeRef = useRef();
  const onResize = () => {
    let newSize = 'desktop';
    if (constants.dimensions.isMobile()) {
      newSize = 'mobile';
    }
    else if (constants.dimensions.isTablet() && !constants.dimensions.isMobile()) {
      newSize = 'tablet';
    }
    if (sizeRef.current !== newSize) {
      setLoading(true);
      requestAnimationFrame(() => {
        sizeRef.current = newSize;
        requestAnimationFrame(() => setLoading(false));
      });
    }
  };
  useWindowResize(onResize);
  useEffect(onResize, []);
  let view = null;
  if (!loading) {
    if (sizeRef.current === 'mobile') {
      view = (<PortfolioMobile items={portfolio} />);
    }
    else if (sizeRef.current === 'tablet') {
      view = (<PortfolioTablet items={portfolio} />);
    }
    else {
      view = (<PortfolioDesktop items={portfolio} />);
    }
  }
  return (
    <section id={endpoints.client.portfolio.replace('#', '')} className='portfolio-root'>
      <div className='portfolio-container'>
        <h2 className='portfolio-heading'>
          {strings.portfolio.title}
        </h2>
        {view}
      </div>
    </section>
  );
}