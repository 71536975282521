import React from 'react';

export default function Divider() {
  return (
    <div className='divider-root'>
      <div className='divider-container'>
        <div className='divider-actual' />
      </div>
    </div>
  );
}