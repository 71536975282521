import React, { useEffect, useState, useRef } from 'react';
import useWindowResize from '../utils/useWindowResize';
import { HeaderSpacerMonitor } from '../navigation/header/header';

const strings = require('../../values/strings');

export default function Address() {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const mapRef = useRef();
  const onResize = () => {
    setWidth(Math.min(window.innerHeight - HeaderSpacerMonitor.get() - 32, 600));
    if (mapRef.current) {
      setHeight(mapRef.current.offsetWidth * 0.75);
    }
  };
  let onMapsRef = (ref) => {
    mapRef.current = ref;
    onResize();
  };
  useEffect(() => {
    onResize();
    return () => {
      mapRef.current = null;
    };
  }, []);
  useWindowResize(onResize);
  return (
    <div className='address-root'>
      <a
        href='https://goo.gl/maps/VB5g6XqgxqwGwWVBA'
        target='_blank'
        rel='noopener noreferrer'
        className='address-container'>
        <span className='address-details'>
          {strings.address.address}
        </span>
        <iframe
          ref={onMapsRef}
          title={strings.address.maps}
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3191.550198102558!2d174.7768242152921!3d-36.87718397993282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d487bf8f103a3%3A0x1cf8f8cb58e375fe!2s21%20Great%20South%20Road%2C%20Epsom%2C%20Auckland%201051!5e0!3m2!1sen!2snz!4v1588561990545!5m2!1sen!2snz'
          frameBorder={0}
          allowFullScreen={true}
          className='address-map'
          style={{
            maxWidth: width ? width + 'px' : undefined,
            height: height ? height + 'px' : undefined
          }} />
      </a>
    </div>
  );
}