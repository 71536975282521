const strings = require('./strings');

export default class page {
  static setTitle(value) {
    let defaultValue = strings.company.name + ' | ' + strings.tagline;
    value = value && (typeof value === 'string') && value.trim().length > 0 ? value.trim() : defaultValue;
    if (!value.endsWith(defaultValue)) {
      value += ' | ' + defaultValue;
    }
    if (document.title !== value) {
      document.title = value;
    }
  }
  static setDescription(description) {
    if (!description || (typeof description !== 'string') || description.trim().length === 0) {
      description = strings.description;
    }
    description = description.trim();
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('name') && element.getAttribute('name') === 'description') {
        document.head.removeChild(element);
      }
    }
    let element = document.createElement('meta');
    element.setAttribute('name', 'description');
    element.setAttribute('content', description);
    document.head.appendChild(element);
  }
  static getDescription() {
    let description = null;
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('name') && element.getAttribute('name') === 'description') {
        description = element.getAttribute('content');
        break;
      }
    }
    return description;
  }
  static setCanonical(value) {
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('rel') && element.getAttribute('rel') === 'canonical') {
        document.head.removeChild(element);
      }
    }
    let element = document.createElement('link');
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', value);
    document.head.appendChild(element);
  }
  static setOpenGraph(values) {
    page.clearOpenGraph();
    if (!values || (typeof values !== 'object')) {
      values = {};
    }
    for (let key of Object.keys(values)) {
      if (!key.startsWith('og:')) {
        values['og:' + key] = values[key];
      }
      if (!key.startsWith('twitter:')) {
        values['twitter:' + key] = values[key];
      }
    }
    for (let key of Object.keys(values)) {
      if (!key.startsWith('og:') && !key.startsWith('twitter:')) {
        delete values[key];
      }
    }
    if (!values['og:title'] || values['og:title'].length === 0) {
      values['og:title'] = document.title;
    }
    if (!values['og:description'] || values['og:description'].length === 0) {
      values['og:description'] = page.getDescription();
    }
    if (!values['og:site_name'] || values['og:site_name'].length === 0) {
      values['og:site_name'] = strings.company.name;
    }
    if (!values['og:url'] || values['og:url'].length === 0) {
      values['og:url'] = window.location.href;
    }
    if (!values['og:image'] || values['og:image'].length === 0) {
      values['og:image'] = window.location.origin + '/assets/img/logo.png';
    }
    if (!values['og:type'] || values['og:type'].length === 0) {
      values['og:type'] = 'website';
    }
    if (!values['twitter:title'] || values['twitter:title'].length === 0) {
      values['twitter:title'] = document.title;
    }
    if (!values['twitter:description'] || values['twitter:description'].length === 0) {
      values['twitter:description'] = page.getDescription();
    }
    if (!values['twitter:card'] || values['twitter:card'].length === 0) {
      values['twitter:card'] = 'summary';
    }
    if (!values['twitter:image'] || values['twitter:image'].length === 0) {
      values['twitter:image'] = window.location.origin + '/assets/img/logo.png';
    }
    for (let key of Object.keys(values)) {
      if (values[key] && values[key].length > 0) {
        let element = document.createElement('meta');
        element.setAttribute('property', key);
        element.setAttribute('content', values[key]);
        element.setAttribute('data-type', 'opengraph');
        document.head.appendChild(element);
      }
    }
  }
  static clearOpenGraph() {
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('data-type') && element.getAttribute('data-type') === 'opengraph') {
        document.head.removeChild(element);
      }
    }
  }
  static logo() {
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('data-type') && element.getAttribute('data-type') === 'logo') {
        document.head.removeChild(element);
      }
    }
    let element = document.createElement('script');
    element.setAttribute('type', 'application/ld+json');
    element.setAttribute('data-type', 'logo');
    element.text = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: window.location.origin,
      'logo':'https://keycapital.co.nz/assets/img/logo.png',
    }, null, 2);
    document.head.appendChild(element);
  }
  static setBreadcrumbs(breadcrumbs) {
    page.clearBreadcrumbs();
    if (breadcrumbs && breadcrumbs.length > 0) {
      let value = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement':
          breadcrumbs.map(b => {
            return {
              '@type': 'ListItem',
              'position': breadcrumbs.indexOf(breadcrumbs.filter(c => c.url === b.url)[0]) + 1,
              'name': b.name,
              'item': window.location.origin + b.url,
            };
          }),
      };
      let element = document.createElement('script');
      element.setAttribute('type', 'application/ld+json');
      element.setAttribute('data-type', 'breadcrumbs');
      element.text = JSON.stringify(value, null, 2);
      document.head.appendChild(element);
    }
  }
  static clearBreadcrumbs() {
    for (let element of document.head.children) {
      if (element && (typeof element.getAttribute === 'function') && element.getAttribute('data-type') && element.getAttribute('data-type') === 'breadcrumbs') {
        document.head.removeChild(element);
      }
    }
  }
  static organisation() {
    let element = document.createElement('script');
    element.setAttribute('type', 'application/ld+json');
    element.setAttribute('data-type', 'organization');
    element.text = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'Key Capital',
      'legalName': 'Key Capital Ltd',
      'url': 'https://keycapital.co.nz/',
      'logo':'https://keycapital.co.nz/assets/img/logo.png',
      'foundingDate': '2020',
      'founders': [
        {
          '@type': 'Person',
          'name': 'Andy Doole'
        },
      ],
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': '21 Great South Road',
        'addressLocality': 'Auckland',
        'addressRegion': 'Auckland',
        'postalCode': '1051',
        'addressCountry': 'NZ'
      },
      'contactPoint': {
        '@type': 'ContactPoint',
        'contactType': 'Contact Us',
        'email': strings.company.email.actual,
      },
    }, null, 2);
    document.head.appendChild(element);
  }
  static setLoaded() {
    setTimeout(() => {
      let element = document.createElement('meta');
      element.setAttribute('id', '_loading_selector_meta_tag');
      document.head.appendChild(element);
    }, 500);
  }
}