import Api from './api';

import axios from 'axios';

const endpoints = require('../values/endpoints');

export default class Captcha extends Api {
  verify(action, token) {
    return axios.post(
      super.getRequestEndpoint(endpoints.api.captcha),
      {
        action: action,
        token: token,
      }
    );
  }
}