import Api from './api';

import captcha from '../values/captcha';

import axios from 'axios';

const endpoints = require('../values/endpoints');

export default class Contact extends Api {
  submit(data) {
    if (captcha.isVerified()) {
      return axios.post(super.getRequestEndpoint(endpoints.api.contact), data);
    }
    else {
      return Promise.reject(new Error('Captcha failed.'));
    }
  }
}