import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PortfolioElement, { PortfolioEmptyElement } from './portfolio-element';
import PortfolioSeeMore from './portfolio-see-more';

function PortfolioMobile(props) {
  const [more, setMore] = useState(false);
  let rows = [];
  for (let i = 0; i < props.items.length; i += 2) {
    let children = [];
    for (let j = 0; j < 2; j++) {
      let item = j + i < props.items.length ? props.items[j + i] : null;
      if (item) {
        children.push(<PortfolioElement key={children.length} item={item} />);
      }
      else {
        children.push(<PortfolioEmptyElement key={children.length} />);
      }
    }
    rows.push(
      <div key={rows.length} className='portfolio-desktop-row'>
        {children}
      </div>
    );
  }
  let aboveFold = rows.slice(0, rows.length > 3 ? 3 : rows.length);
  let belowFold = rows.length > 3 ? rows.slice(3) : [];
  return (
    <div className='portfolio-mobile-root'>
      {aboveFold}
      {
        more &&
        belowFold
      }
      {
        belowFold.length > 0 &&
        <PortfolioSeeMore open={more} onClick={() => setMore(!more)} />
      }
    </div>
  );
}

PortfolioMobile.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PortfolioMobile;