import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const strings = require('../../../values/strings');

function CreditsDialog(props) {
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>{strings.credits.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {strings.credits.body}
        </DialogContentText>
        <DialogContentText>
          <ul>
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {strings.credits.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreditsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function Credits() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div onClick={() => setOpen(true)} onKeyUp={() => setOpen(true)} role='button' tabIndex={0} className='footer-accessories-credits'>
        {strings.credits.title}
      </div>
      <CreditsDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}