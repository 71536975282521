import React, { useState, useEffect, useRef } from 'react';

import AppBar from '@material-ui/core/AppBar';

import HeaderNavigation from './header-navigation';
import HeaderNavigationMobile from './header-navigation-mobile';

const ResizeSensor = require('css-element-queries/src/ResizeSensor');

const endpoints = require('../../../values/endpoints');
const strings = require('../../../values/strings');

export class HeaderSpacerMonitor {
  static get() {
    let header = document.getElementById('header');
    if (header) {
      return header.offsetHeight;
    }
    else {
      return 0;
    }
  }
  constructor(callback) {
    let appBar = document.getElementById('header');
    if (appBar) {
      this.resizeSensor = new ResizeSensor(appBar, () => {
        if (typeof callback === 'function') {
          callback(appBar.offsetHeight);
        }
      });
    }
  }
  detach() {
    try { this.resizeSensor.detach(); } catch (err) { }
    this.resizeSensor = null;
  }
}

export function HeaderSpacer(props) {
  const [height, setHeight] = useState(0);
  const currentHeight = useRef(height);
  const monitor = useRef();
  useEffect(() => {
    monitor.current = new HeaderSpacerMonitor((newHeight) => {
      if (currentHeight.current !== newHeight) {
        setHeight(newHeight);
        currentHeight.current = newHeight;
      }
    });
    return () => {
      if (monitor.current) {
        monitor.current.detach();
      }
      monitor.current = null;
    };
  }, []);
  return (
    <div {...props} style={{ height: height }} />
  );
}

export default function Header() {
  return (
    <AppBar id='header' position='fixed' elevation={0} className='header-element'>
      <div className='header-root'>
        <a data-analytics='header-logo' href={endpoints.client.home} className='header-title-container'>
          <img src='/assets/img/logo-header.png' alt={strings.company.name} className='header-title-logo' />
        </a>
        <div className='header-spacer' />
        <div className='header-contents'>
          <div className='header-element-desktop'>
            <HeaderNavigation />
          </div>
          <div className='header-element-mobile'>
            <HeaderNavigationMobile />
          </div>
        </div>
      </div>
    </AppBar>
  );
}