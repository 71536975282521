const constants = require('../values/constants');

export default class Api {
  getRequestEndpoint(endpoint) {
    if (endpoint) {
      if (!endpoint.startsWith('/')) {
        endpoint = '/' + endpoint;
      }
      if (!constants.isProduction()) {
        endpoint = window.location.protocol + '//' + window.location.hostname + ':3000' + endpoint;
      }
    }
    else {
      endpoint = '';
    }
    return endpoint;
  }
}