import React from 'react';
import Credits from './credits';

const strings = require('../../../values/strings');
const endpoints = require('../../../values/endpoints');

export default function Footer() {
  return (
    <footer className='footer-root'>
      <a data-analytics='header-logo' href={endpoints.client.home} className='footer-logo-container'>
        <img src='/assets/img/logo.png' alt={strings.company.name} className='footer-logo-image' />
      </a>
      <div className='footer-accessories'>
        <div className='footer-accessories-copyright'>{strings.copyright}</div>
        <span className='footer-accessories-captcha'>
          {'This site is protected by reCAPTCHA and the Google '}
          <a data-analytics='footer-captcha-privacy' href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
          {' and '}
          <a data-analytics='footer-captcha-terms' href='https://policies.google.com/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a>
          {' apply.'}
        </span>
        <Credits />
      </div>
    </footer>
  );
}