import React from 'react';
import Button from '@material-ui/core/Button';

const strings = require('../../values/strings');
const endpoints = require('../../values/endpoints');

export default function About() {
  return (
    <section id={endpoints.client.about.replace('#', '')} className='about-root'>
      <div className='about-container'>
        <h2 className='about-heading'>
          {strings.about.title}
        </h2>
        <Button href={endpoints.client.contact} variant='outlined' className='about-button'>
          {strings.about.button}
        </Button>
      </div>
    </section>
  );
}