import React, { useState, useEffect } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { HeaderSpacerMonitor, HeaderSpacer } from '../navigation/header/header';
import useWindowResize from '../utils/useWindowResize';

const strings = require('../../values/strings');
const constants = require('../../values/constants');

export default function Hero() {
  const [height, setHeight] = useState();
  const onResize = () => {
    setHeight('calc(' + (constants.dimensions.isTablet() ? 90 : 100) + 'vh - ' + HeaderSpacerMonitor.get() + 'px)');
  };
  const onClick = () => window.scrollTo({ top: window.innerHeight - HeaderSpacerMonitor.get(), behavior: 'smooth' });
  useWindowResize(onResize);
  useEffect(onResize, []);
  return (
    <>
      <HeaderSpacer />
      <div className='hero-root' style={{ height: height }}>
        <img
          alt={strings.company.name}
          id='hero-logo'
          src='/assets/img/hero.jpg'
          onLoad={onResize}
          className='hero-logo' />
        <div className='hero-logo-cover' />
        {/* <span className='hero-soon'>Coming Soon</span> */}
        <div
          onClick={onClick}
          onKeyUp={onClick}
          role='button'
          tabIndex={0}
          className='hero-more'>
          <ExpandMoreIcon className='hero-more-icon' />
        </div>
      </div>
    </>
  );
}