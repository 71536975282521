import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const strings = require('../../../values/strings');

function PortfolioDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth='md'
      scroll='body'
      aria-labelledby='portfolio-dialog-title'
      aria-describedby='portfolio-dialog-description'>
      <DialogTitle id='portfolio-dialog-title'>
        {props.item.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='portfolio-dialog-description'
          tabIndex={-1}
          className='portfolio-dialog-body-container'>
          <p className='portfolio-dialog-body-copy'>{props.item.body}</p>
          <div className='portfolio-dialog-body-image-container'>
            <img
              alt={props.item.title}
              loading='lazy'
              src={props.item.image}
              className='portfolio-dialog-body-image' />
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color='primary'>
          {strings.portfolio.button.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PortfolioDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

export default PortfolioDialog;