import React, { useState, useEffect, useRef } from 'react';

import TeamDesktop from './team-desktop';
import TeamMobile from './team-mobile';

import useWindowResize from '../../utils/useWindowResize';

const strings = require('../../../values/strings');
const team = require('../../../values/team');
const endpoints = require('../../../values/endpoints');
const constants = require('../../../values/constants');

export default function Team() {
  const [loading, setLoading] = useState(true);
  const sizeRef = useRef();
  const onResize = () => {
    let newSize = 'desktop';
    if (constants.dimensions.isMobile()) {
      newSize = 'mobile';
    }
    if (sizeRef.current !== newSize) {
      setLoading(true);
      requestAnimationFrame(() => {
        sizeRef.current = newSize;
        requestAnimationFrame(() => setLoading(false));
      });
    }
  };
  useWindowResize(onResize);
  useEffect(onResize, []);
  let view = null;
  if (!loading) {
    if (sizeRef.current === 'mobile') {
      view = (<TeamMobile items={team} />);
    }
    else {
      view = (<TeamDesktop items={team} />);
    }
  }
  return (
    <section id={endpoints.client.team.replace('#', '')} className='team-root'>
      <div className='team-container'>
        <h2 className='team-heading'>
          {strings.team.title}
        </h2>
        {view}
      </div>
    </section>
  );
}