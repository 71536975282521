module.exports = {

  dimensions: {
    mobile: 500,
    tablet: 880,
    desktop: 1080,
    extra: 1440,
    isMobile: () => window.innerWidth <= module.exports.dimensions.mobile,
    isTablet: () => window.innerWidth <= module.exports.dimensions.tablet,
    isDesktop: () => window.innerWidth > module.exports.dimensions.mobile,
    isExtraWide: () => window.innerWidth >= module.exports.dimensions.extra,
  },

  captcha: '6LcDSbUoAAAAAAuyjPeOrsOh7iUHhkQkkbi4IYtl',

  isProduction: () => window && window.location && window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1',

  getParent: (element) => {
    let parent = null;
    if (element) {
      try {
        parent = (element.parentElement ? element.parentElement : element.parentNode);
      } catch (err) {
        try { parent = element.parentNode; } catch (err) {}
      }
    }
    return parent;
  },

}