module.exports = {

  client: {

    home: '/',
    about: '#about',
    portfolio: '#portfolio',
    team: '#team',
    contact: '#contact',

  },

  api: {

    ping: '/api/ping',
    contact: '/api/contact',
    captcha: '/api/captcha',

  },

  getEndpoints: () => {
    let _getEndpoints = (parent, endpoint) => {
      let keys = [];
      for (let key of Object.keys(endpoint)) {
        let value = endpoint[key];
        if (typeof value === 'string') {
          let object = {};
          object[parent + '.' + key] = value;
          keys.push(object);
        }
        else if (typeof value === 'object') {
          keys = keys.concat(_getEndpoints(parent + '.' + key, value));
        }
      }
      return keys;
    };
    return _getEndpoints('endpoints', module.exports);
  },

};