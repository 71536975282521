import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const strings = require('../../../values/strings');

function ContactForm(props) {
  return (
    <form noValidate={true} className='contact-form-root'>
      <div className='contact-form-row'>
        <TextField
          autoComplete='name'
          name={strings.contact.inputs.name}
          label={strings.contact.inputs.name}
          aria-label={strings.contact.inputs.name}
          value={props.name}
          onChange={(event) => props.onNameChange(event.target.value)}
          error={props.errors ? !props.name || props.name.length === 0 : false}
          disabled={!props.captcha}
          fullWidth={true}
          variant='outlined'
          InputLabelProps={{
            classes: {
              root: 'contact-form-input-label',
              shrink: 'contact-form-input-label-shrink',
            },
          }}
          InputProps={{
            classes: {
              input: 'contact-form-input',
            },
          }}
          className='contact-form-left' />
        <TextField
          name={strings.contact.inputs.company}
          aria-label={strings.contact.inputs.company}
          label={strings.contact.inputs.company}
          value={props.company}
          onChange={(event) => props.onCompanyChange(event.target.value)}
          disabled={!props.captcha}
          fullWidth={true}
          InputLabelProps={{
            classes: {
              root: 'contact-form-input-label',
              shrink: 'contact-form-input-label-shrink',
            },
          }}
          InputProps={{
            classes: {
              input: 'contact-form-input',
            },
          }}
          variant='outlined' />
      </div>
      <div className='contact-form-row'>
        <TextField
          autoComplete='email'
          type='email'
          name={strings.contact.inputs.email}
          label={strings.contact.inputs.email}
          aria-label={strings.contact.inputs.email}
          value={props.email}
          onChange={(event) => props.onEmailChange(event.target.value)}
          error={props.errors ? !props.email || props.email.length === 0 : false}
          disabled={!props.captcha}
          fullWidth={true}
          variant='outlined'
          InputLabelProps={{
            classes: {
              root: 'contact-form-input-label',
              shrink: 'contact-form-input-label-shrink',
            },
          }}
          InputProps={{
            classes: {
              input: 'contact-form-input',
            },
          }}
          className='contact-form-left' />
        <TextField
          autoComplete='tel'
          type='tel'
          name={strings.contact.inputs.phone}
          label={strings.contact.inputs.phone}
          aria-label={strings.contact.inputs.phone}
          value={props.phone}
          onChange={(event) => props.onPhoneChange(event.target.value)}
          disabled={!props.captcha}
          fullWidth={true}
          InputLabelProps={{
            classes: {
              root: 'contact-form-input-label',
              shrink: 'contact-form-input-label-shrink',
            },
          }}
          InputProps={{
            classes: {
              input: 'contact-form-input',
            },
          }}
          variant='outlined' />
      </div>
      <TextField
        label={strings.contact.inputs.message.title}
        aria-label={strings.contact.inputs.message.placeholder}
        placeholder={strings.contact.inputs.message.placeholder}
        value={props.message}
        onChange={(event) => props.onMessageChange(event.target.value)}
        disabled={!props.captcha}
        fullWidth={true}
        multiline={true}
        rows={4}
        rowsMax={6}
        variant='outlined'
        InputLabelProps={{
          classes: {
            root: 'contact-form-input-label',
            shrink: 'contact-form-input-label-shrink',
          },
        }}
        InputProps={{
          classes: {
            input: 'contact-form-input',
          },
        }}
        className='contact-form-message' />
      <div className='contact-form-buttons'>
        <Button variant='outlined' disabled={!props.captcha} onClick={props.onSubmit} className='contact-form-button'>
          {strings.contact.button}
        </Button>
      </div>
    </form>
  );
}

ContactForm.propTypes = {
  captcha: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  onCompanyChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onMessageChange: PropTypes.func.isRequired,
  errors: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;